<template>
  <div>
  <div class="mb-2">
    <div class="text-danger font-weight-bold fs-6">
      <span class="dot"></span>
      {{ $t('backoffice.classifiers.private') }}
    </div>
  </div>
  <b-table class="position-relative" :items="organizationList" responsive :fields="fields" :busy="busy"
    :thead-class="!organizationList || organizationList.length == 0 ? 'd-none' : ''" primary-key="id" show-empty
    empty-text="No matching records found">
    <!-- Column: name with avatar -->
    <template #cell(nameAvatar)="{ item }">
      <b-media class="d-flex align-items-center">
        <b-link :to="getOrganizationLocation(item)" :target="currentCollectiveKey ? '_blank' : '_self'"
          class="text-dark d-flex align-items-center">
          <safe-img class="logo" :src="getImageResource(item.logoURL)" :placeholder="placeholder" />
          <div class="ml-1">
            <p class="font-weight-extrabold mb-0">
              {{ translate(item.name) }}
            </p>
            <!-- eslint-disable-next-line vue/no-v-html-->
            <small v-if="item.headline" class="text-muted mb-0" v-html="translate(item.headline)" />
          </div>
        </b-link>
      </b-media>
    </template>

    <!-- Loading table -->
    <template #table-busy>
      <div class="text-center p-5">
        <b-spinner size="2rem" class="d-block m-auto m-1" variant="primary" label="Loading..." />
      </div>
    </template>

    <!-- Empty table -->
    <template #empty>
      <b-row class="horizontal-placeholder w-100">
        <b-col cols="12">
          <img :src="organizationsPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t('available.message', { itemName: $t('organizations.title') }) }}
          </p>
        </b-col>
      </b-row>
    </template>
  </b-table>
  </div>
</template>

<script>
import OrganizationsPlaceholder from '@/assets/images/placeholders/light/organizations.svg';
import AvatarPlaceholder from '@/assets/images/placeholders/light/organizations-logo.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { getImageResource } from '@/@core/utils/image-utils';
import SafeImg from '@core/components/safe-img/SafeImg.vue';

export default {
  components: {
    SafeImg,

  },
  data() {
    return {
      organizationList: [],
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    noShowOrganizations: Boolean,
    isSearching: Boolean,
    filter: Boolean,
    busy: Boolean,
    societies: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fields() {
      return [
        {
          key: 'nameAvatar',
          label: this.$t('name.title'),
          sortable: false,
        },
        // {
        //   key: 'description',
        //   label: this.$t('organizations.edit.description.label'),
        //   sortable: false,
        // },
        /*{
          key: 'actions',
          label: this.$t('backoffice.members.actions'),
          sortable: false,
        },*/
      ];
    },
    currentCollectiveKey() {
      return this.$store.getters.currentCollective.key === '5e26cee0-0cbc-11ed-89a7-a1d49fe606c5';
    },
    organizationsPlaceholder() {
      return OrganizationsPlaceholder;
    },
    placeholder() {
      return AvatarPlaceholder;
    },
    isDetailView() {
      return this.$route.name === 'organizations';
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    classifiersTree() {
      if (this.$store.getters.classifiersTree.organizations) {
        return this.$store.getters.classifiersTree.organizations.unpaginated;
      }
      return [];
    },
    classifiers() {
      if (this.$store.getters.classifiers.organizations) {
        return this.$store.getters.classifiers.organizations.unpaginated;
      }
      return [];
    },
  },
  async created() {
    await this.fetchClassifiers();
    await this.fetchClassifiersTree(true);
    this.createClassifiersColumns()
  },
  methods: {
    async fetchClassifiersTree(force = false) {
      await this.$store.dispatch("getItems", {
        itemType: "classifiersTree",
        storedKey: 'organizations',
        forceAPICall: force,
        requestConfig: {
          modelType: 'organizations',
          morphType: 'organization',
          isForBackoffice: true,
          count: 1000,
        },
      });
    },
    async fetchClassifiers() {
      await this.$store.dispatch('getItems', {
        itemType: 'backoffice/classifiers',
        storedKey: 'organizations',
        customName: 'classifiers',
        page: 1,
        requestConfig: {
          modelType: 'organizations',
          offset: 0,
          count: 1000,
          dontCheckShares: true,
          isForBackoffice: true
        },
      });
    },

    createClassifiersColumns() {
      if (this.classifiersTree && this.classifiersTree.length > 0) {
        let typeNames = this.classifiersTree.map((item) => item.name);
        typeNames = [...new Set(typeNames)];
        this.dataClassifiers();
        return typeNames.map((item, index) => {
          const key = item[this.locale] ? item[this.locale] : Object.values(item)[0];
          this.fields.push({
            key,
            label: key,
            sortable: false,
            thClass: `pl-1 ${this.classifiersTree[index].isPrivate ? 'text-danger' : ''}`,
            tdAttr: (value, key, it) => {
              let style = '';
              it.classifiers.map(x => {
                if ((x.typeName[this.locale] ? x.typeName[this.locale] : Object.values(x.typeName)[0]) == (item[this.locale] ? item[this.locale] : Object.values(item)[0])) {
                  style = { style: `color: ${x.backgroundColor !== '#ffffff' ? x.backgroundColor : x.fontColor}; font-weight: 600` }
                }
                
              });
              return style;
            },
            formatter: (value) => {
              const allItems = [];
              if (value && Array.isArray(value)) {
                value?.map((item) => {
                  if (typeof item === 'object') {
                    allItems.push(item[this.locale] ? item[this.locale] : Object.values(item)[0]);
                  } else {
                    allItems.push(item);
                  }
                });
                return allItems.join(', ');
              }
              return value;
            },
          });
        });
      }
    },

    dataClassifiers() {
      this.organizationList = this.items.map((item) => item);
      this.organizationList.map((n, index) => {
        n.classifiers.map((i) => {
          this.classifiers.map((x, p) => {
            if (i.key === x.key) {
              const typeName = x.typeName[this.locale] ? x.typeName[this.locale] : Object.values(x.typeName)[0];
              if (Array.isArray(this.organizationList[index][typeName])) {
                if (this.organizationList[index][typeName].includes(i.name)) {
                  return;
                }
                this.organizationList[index][typeName].push(i.name);
              } else {
                this.organizationList[index][typeName] = [];
                this.organizationList[index][typeName].push(i.name);
              }
            }
          });
        });
      });
    },

    getOrganizationLocation(organization) {
      if (!organization.name) {
        return null;
      }
      return {
        name: this.societies ? 'societies-details' : 'backoffice-organization-details',
        params: {
          ...(this.societies ? { organizationId: organization.key } : { slug: organization.slug }),
          communityId: this.$route.params.communityId,
        },
      };
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    getImageResource,
    async toggleLike(item) {
      await this.$store.dispatch('toggleLike', {
        itemType: 'communitiesOthers',
        storedKey: 'organizations',
        morphType: 'organization',
        key: item.key,
      });
      if (item.likedByMember) {
        item.likedByMember = false;
        --item.totalLikes;
      } else {
        item.likedByMember = true;
        ++item.totalLikes;
      }
    },
  },

};
</script>

<style scoped>
.dot {
  height: 10px;
  width: 10px;
  background-color: #dc3545;
  border-radius: 50%;
  display: inline-block;
}
</style>
